<template>
    <section class="users container-fluid p-3">
        <div class="position-sticky title d-flex mb-3">
            <div class="d-flex d-xl-none align-items-center">
                <button-toggle-side-bar></button-toggle-side-bar>
            </div>
            <h1 class="display-4 font-weight-bolder col pl-xl-0 mb-0">
                Settings
            </h1>
        </div>
        <div class="row">
            <div class="col-md-6">
                <region-list></region-list>

                <!-- <b-form-group label="Language"
                              label-for="language">
                    <b-form-select id="language"
                                   required
                                   v-model="languageSelected">
                        <option v-for="lang in langs"
                                v-bind:key="lang.value"
                                v-bind:value="lang.value">
                            {{ lang.text }}
                        </option>
                    </b-form-select>
                </b-form-group> -->
            </div>
            <div class="col-6">

            </div>
        </div>
    </section>
</template>

<script>
//import { BFormGroup, BFormSelect, } from "bootstrap-vue";
export default {
    name: "Settings",
    metaInfo () {
        return {
            "title": "Settings",
        };
    },
    components: {
        //BFormGroup, BFormSelect,
        ButtonToggleSideBar: () => import("@/components/layout/ButtonToggleSideBar"),
        RegionList: () => import("@/components/Settings/RegionList"),
    },
    data () {
        return {
            langs: [{
                value: "en",
                text: "English",
            }, {
                value: "zh-hk",
                text: "繁體中文",
            }]
        };
    },
    computed: {
        languageSelected: {
            get () {
                return this.$i18n.locale;
            },
            set (val) {
                localStorage.setItem("hkrecycle-mrb.user-lang", val);
                this.$i18n.locale = val;
            },
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
